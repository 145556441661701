import * as React from "react";
import { IconClass, Icons } from "../../_typescript/type";

//@ts-ignore
import ArrowBoldLeft from "../../assets-svg/arrowBoldLeft.svg";
//@ts-ignore
import EmptyCake from "../../assets-svg/emptyCake.svg";
//@ts-ignore
import ArrowBoldRight from "../../assets-svg/arrowBoldRight.svg";
//@ts-ignore
import ArrowDownRight from "../../assets-svg/arrowDownRight.svg";
//@ts-ignore
import ArrowDownRotate from "../../assets-svg/arrowDownRotate.svg";
//@ts-ignore
import ArrowStylesUp from "../../assets-svg/arrowStylesUpIcon.svg";
//@ts-ignore
import ArrowStylesDown from "../../assets-svg/arrowStylesDownIcon.svg";
//@ts-ignore
import BusinessArrow from "../../assets-svg/businessArrow.svg";
//@ts-ignore
import PeopleArrow from "../../assets-svg/peopleArrow.svg";
//@ts-ignore
import ValueArrow from "../../assets-svg/valueArrow.svg";
//@ts-ignore
import Bag from "../../assets-svg/bag.svg";
//@ts-ignore
import Clock from "../../assets-svg/clock.svg";
//@ts-ignore
import Cake from "../../assets-svg/cake.svg";
//@ts-ignore
import Freccette from "../../assets-svg/freccette.svg";
//@ts-ignore
import CakeDown from "../../assets-svg/cakeDown.svg";
//@ts-ignore
import ConnectionComputers from "../../assets-svg/connectionComputersIcon.svg";
//@ts-ignore
import ComputerSchema from "../../assets-svg/computersSchema.svg";
//@ts-ignore
import Tim from "../../assets-svg/tim.svg";
//@ts-ignore
import ArrowIconDown from "../../assets-svg/arrowIconDown.svg";
//@ts-ignore
import Alitalia from "../../assets-svg/alitalia.svg";
//@ts-ignore
import Leonardo from "../../assets-svg/leonardo.svg";
//@ts-ignore
import Megafono from "../../assets-svg/megafono.svg";
//@ts-ignore
import Pig from "../../assets-svg/pig.svg";
//@ts-ignore
import StrettaDiMano from "../../assets-svg/strettadiMano.svg";
//@ts-ignore
import OtherCake from "../../assets-svg/otherCake.svg";

//@ts-ignore
import CakeLeft from "../../assets-svg/cakeLeft.svg";
//@ts-ignore
import BusinessSchema from "../../assets-svg/businessSchema.svg";

//@ts-ignore
import PieRight from "../../assets-svg/pieRight.svg";

//@ts-ignore
import Statistics from "../../assets-svg/statistics.svg";

//@ts-ignore
import Almaviva from "../../assets-svg/ALMAVIVA.svg";

//@ts-ignore
import Sda from "../../assets-svg/SDA.svg";

//@ts-ignore
import Sky from "../../assets-svg/SKY.svg";

//@ts-ignore
import Vodafone from "../../assets-svg/VODAFONE.svg";

//@ts-ignore
import PosteItaliane from "../../assets-svg/POSTE_ITALIANE.svg";

//@ts-ignore
import Windtre from "../../assets-svg/WINDTRE.svg";

//@ts-ignore
import Enel from "../../assets-svg/ENEL.svg";

//@ts-ignore
import * as styles from "./Icon.module.css";

interface IIcon {
  icon?: Icons | null;
  className?: string;
  iconClass?: IconClass;
}

const Icon = ({ icon, iconClass, className }: IIcon) => {
  if (!icon) {
    return null;
  }

  const classNames = `${className ? className : ""} ${styles.base} ${
    iconClass ? styles[iconClass] : ""
  }`;
  switch (icon) {
    case "arrowBoldLeft":
      return <ArrowBoldLeft className={classNames} />;
    case "arrowBoldRight":
      return <ArrowBoldRight className={classNames} />;
    case "arrowDownRight":
      return <ArrowDownRight className={classNames} />;
    case "arrowDownRotate":
      return <ArrowDownRotate className={classNames} />;
    case "businessArrow":
      return <BusinessArrow className={classNames} />;
    case "valueArrow":
      return <ValueArrow className={classNames} />;
    case "emptyCake":
      return <EmptyCake className={classNames} />;
    case "peopleArrow":
      return <PeopleArrow className={classNames} />;
    case "arrowIconDown":
      return <ArrowIconDown className={classNames} />;
    case "arrowStylesUp":
      return <ArrowStylesUp className={classNames} />;
    case "arrowStylesDown":
      return <ArrowStylesDown className={classNames} />;
    case "bag":
      return <Bag className={classNames} />;
    case "freccette":
      return <Freccette className={classNames} />;
    case "cake":
      return <Cake className={classNames} />;
    case "cakeDown":
      return <CakeDown className={classNames} />;
    case "cakeLeft":
      return <CakeLeft className={classNames} />;
    case "otherCake":
      return <OtherCake className={classNames} />;
    case "clock":
      return <Clock className={classNames} />;
    case "connectionComputers":
      return <ConnectionComputers className={classNames} />;
    case "computerSchema":
      return <ComputerSchema className={classNames} />;
    case "businessSchema":
      return <BusinessSchema className={classNames}></BusinessSchema>;
    case "megafono":
      return <Megafono className={classNames} />;
    case "tim":
      return <Tim className={classNames} />;
    case "alitalia":
      return <Alitalia className={classNames} />;
    case "leonardo":
      return <Leonardo className={classNames} />;
    case "pig":
      return <Pig className={classNames} />;
    case "strettaDiMano":
      return <StrettaDiMano className={classNames} />;
    case "pieRight":
      return <PieRight className={classNames}></PieRight>;
    case "statistics":
      return <Statistics className={classNames}></Statistics>;
    case "almaviva":
      return <Almaviva className={classNames}></Almaviva>;
    case "sky":
      return <Sky className={classNames}></Sky>;
    case "enel":
      return <Enel className={classNames}></Enel>;
    case "sda":
      return <Sda className={classNames}></Sda>;
    case "vodafone":
      return <Vodafone className={classNames}></Vodafone>;
    case "windtre":
      console.log("windtre", classNames);
      return <Windtre className={classNames}></Windtre>;
    case "posteItaliane":
      return <PosteItaliane className={classNames}></PosteItaliane>;
  }
  return null;
};

export default Icon;
