import React from "react";
import MainNavigation from "../MainNavigation/MainNavigation";
import Footer from "../Footer/Footer";

//@ts-ignore
import * as styles from "./Layout.module.css";

interface ILayout {
  children: JSX.Element;
}

const Layout = ({ children }: ILayout) => {
  return (
    <div>
      <MainNavigation />
      <main className={styles.mainContainer}>{children}</main>
      <Footer />
    </div>
  );
};

export default Layout;
