//@ts-ignore
import { classNames } from "../../helpers/classes";
import { Link } from "gatsby";
import * as React from "react";
import { useState } from "react";
import { Helmet } from "react-helmet";

//@ts-ignore
import * as styles from "./MainNavigation.module.css";

const MainNavigation = () => {
  const [toggle, setToggle] = useState(false);

  const items = [
    {
      url: "#creiamo-valore",
      title: "about",
      id: "1111",
    },
    {
      url: "#servizi",
      title: "servizi",
      id: "1112",
    },
    {
      url: "#portfolio",
      title: "portfolio",
      id: "1113",
    },
    {
      url: "#contatti",
      title: "contatti",
      id: "1114",
    },
  ];

  return (
    <div className={`${styles.container}`}>
      {/* @ts-ignore */}
      {/* <Helmet
        bodyAttributes={{
          class: classNames({
            "-disable-scroll": toggle,
          }),
        }}
      /> */}
      {/* Content */}
      <div className={`grid-12--desktop-small ${styles.content}`}>
        {/* logoLink */}
        <Link className={styles.logoLink} to="/">
          {/* Logo */}
          <img className={styles.logo} src={"/images/menu-logo.svg"} alt="" />
        </Link>

        {/* Mobile & Desktop Menu Icon Container */}
        <div
          className={styles.IconContainer}
          onClick={() => {
            return setToggle(!toggle);
          }}
        >
          {/* Hamburger & Cross */}
          <div className={toggle ? `${styles.cross}` : `${styles.hamburger}`}>
            <div />
          </div>
        </div>

        {/* Mobile & Desktop Nav */}
        <nav
          className={classNames({
            [styles.mobileNav]: true,
            [styles.mobileNavOpen]: toggle,
            [styles.desktopNav]: !toggle,
          })}
        >
          {/* List */}
          <ul className={styles.list}>
            {items.map((item) => {
              return (
                //List Items
                <li
                  key={item.id}
                  className={styles.listItems}
                  onClick={() => {
                    return setToggle(!toggle);
                  }}
                >
                  {/* List Links */}
                  <Link className={styles.listLinks} to={"/" + item.url}>
                    {item.title}
                  </Link>
                </li>
              );
            })}
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default MainNavigation;
