// extracted by mini-css-extract-plugin
export var companyAddressContainer = "Footer-module--companyAddressContainer--GYHuG";
export var companyHeadline = "Footer-module--companyHeadline--1v4EV";
export var content = "Footer-module--content--Kusw8";
export var displayNone = "Footer-module--displayNone--dlkgK";
export var headline = "Footer-module--headline--t+k8f";
export var hyphen = "Footer-module--hyphen--RhNyL";
export var image = "Footer-module--image--5AHOm";
export var informationItemLink = "Footer-module--informationItemLink--VTZwM";
export var logoAndParagraphContainer = "Footer-module--logoAndParagraphContainer--9pvfr";
export var maps = "Footer-module--maps--ROd0w";
export var mapsContactAndSocialContainer = "Footer-module--mapsContactAndSocialContainer--IsA1C";
export var phoneNumber = "Footer-module--phoneNumber--knK7c";
export var poweredByDescription = "Footer-module--poweredByDescription--14Vbd";
export var privacyDescription = "Footer-module--privacyDescription--HjnKB";
export var privacyPolicyAndAddressContainer = "Footer-module--privacyPolicyAndAddressContainer--yOuIG";
export var streetAddress = "Footer-module--streetAddress--hCnh-";
export var subContentContainer = "Footer-module--subContentContainer--V-d0m";
export var subTitleLabel = "Footer-module--subTitleLabel--27B1V";
export var whiteLink = "Footer-module--whiteLink--ilaiY";