import Headline from "../Headline/Headline";
import { Link } from "gatsby";
import * as React from "react";

//@ts-ignore
import * as styles from "./Footer.module.css";

const Footer = () => {
  return (
    <footer id="contatti" className={styles.container}>
      {/* content */}
      <div
        className={`
                    grid-8--mobile
                    grid-8--tablet
                    grid-12--desktop-small
                    ${styles.content}
                `}
      >
        {/* Headline */}
        <Headline
          className={styles.headline}
          tagName="h2"
          headline="Contattaci"
        />

        {/* Sub Content Container */}
        <div className={styles.subContentContainer}>
          {/* Logo and Paragraph Container */}
          <div className={styles.logoAndParagraphContainer}>
            {/* Image */}
            <img
              className={styles.image}
              src={"/images/footer-logo.svg"}
              alt="footer p&b logo"
            />
            {/* Company Address */}
            <div
              className={`
                                grid-3--mobile
                                grid-4--tablet
                                gird-6--desktop-small
                                ${styles.companyAddressContainer}
                            `}
            >
              <Headline
                className={styles.companyHeadline}
                tagName="h3"
                headline="Sede Roma"
              />

              {/* Street Address */}
              <span className={styles.streetAddress}>
                Via Giovanni Antonelli, 47
                <span className={styles.hyphen}>-</span>
                <br className={styles.displayNone}></br>
                00197 ROMA
              </span>
            </div>
          </div>

          {/* Maps Contact and Social Container */}
          <div className={styles.mapsContactAndSocialContainer}>
            {/* Maps */}
            {/* TODO: add the real iframe with the maps */}
            <div className={`grid-7--desktop-small ${styles.maps}`}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2968.536649376321!2d12.481704751028433!3d41.924318470397516!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x132f60e1d8159101%3A0xd97f685cfcb1a031!2sVia%20Giovanni%20Antonelli%2C%2047%2C%2000197%20Roma%20RM!5e0!3m2!1sen!2sit!4v1658424473723!5m2!1sen!2sit"
                width="600"
                height="450"
                className={styles.maps}
                loading="lazy"
              ></iframe>
            </div>

            {/* Contact and Socials Container */}
            <div
              style={{ marginTop: "auto" }}
              className="grid-4--desktop-small"
            >
              {/* Sub Title Label */}
              <span className={styles.subTitleLabel}>
                Contact us:
                <br></br>
              </span>
              <Link
                className={styles.informationItemLink}
                to="mailto:info@peopleandbusinessvalue.com"
              >
                info@peoplebusinessvalue.com
                <br></br>
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* Privacy Policy and address */}
      <div className={styles.privacyPolicyAndAddressContainer}>
        <div className="grid-5--mobile">
          {/* Privacy Description */}
          <p className={styles.privacyDescription}>
            <Link to="/privacy-policy" className={styles.whiteLink}>
              Privacy Policy
            </Link>
            <br></br>© 2022 P&B Value is a company registered in Italy, Roma in
            Via Giovanni Antonelli 47 00197 RM
            <br></br>P.IVA n. 07127661002
          </p>

          {/* Powered by description */}
          <span className={styles.poweredByDescription}>
            Powered by imo creativy
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
