/**
 * Replaces &shy; with \u00AD
 *
 * @param {string} value
 * @returns {string}
 */
export const allowSoftHyphens = (value?: string) => {
    if (!value) {
        return '';
    }
    return value
        .replace(
            // normal soft hyphens
            /&shy;/g,
            '\u00AD'
        )
        .replace(
            // when added in RichText
            /&amp;shy;/g,
            '\u00AD'
        );
};

export const stripTagFromHtml = (tagName: string, html?: string) => {
    if (!html) {
        return '';
    }
    return html.replace(`<${tagName}>`, '').replace(`</${tagName}>`, '');
};

export const toCamelCase = (s: string) => {
    s = s.toLowerCase();
    let match = s.match(/[^a-z]/);
    while (match && 'undefined' !== typeof match.index) {
        // @ts-ignore
        s =
            s.slice(0, match.index) +
            s.slice(match.index + 1, match.index + 2).toUpperCase() +
            s.slice(match.index + 2);
        match = s.match('-');
    }

    return s;
};

export const toSnakeCase = (str: string) => {
    // @ts-ignore
    return str
        .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
        .map((x) => {
            return x.toLowerCase();
        })
        .join('_');
};

export const capitalizeFirstLetter = (word: string) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
};

export const maxLengthRespectingWordBreaks = (text: string, maxLength: number) => {
    if (!text) {
        return '';
    }

    if (text.length < maxLength) {
        return text;
    }

    let returnText = '';
    let addEllipsis = false;
    for (const word of text.split(/\s/)) {
        if (returnText.length + word.length + 1 > maxLength) {
            addEllipsis = true;
            break;
        }
        returnText += ` ${word}`;
    }

    // if last char is a full stop or comma we remove it

    returnText = returnText.trim();

    if (['.', ',',].includes(returnText.slice(-1))) {
        returnText = returnText.slice(0, -1);
    }

    if (addEllipsis) {
        returnText += ' …';
    }

    return returnText;
};

export const restrictChars = (value: string, maxChars: number) => {
    value = value.trim();
    if (value.length > maxChars) {
        return value.slice(0, maxChars);
    }
    return value;
};

export const restrictWords = (value: string, maxWords: number, ellipsis: boolean = true) => {
    value = value.trim();
    let trimmed = '';
    let hasWords = 0;
    const words = value.split(/\s/g);
    for (const word of words) {
        // whitespace should not count as word
        if (0 === word.trim().length) {
            maxWords++;
        }
        if ('-' === word) {
            // should not be counted as word
            maxWords++;
        }
        trimmed += word;
        trimmed += value.slice(trimmed.length, trimmed.length + 1);
        hasWords++;
        if (hasWords === maxWords) {
            break;
        }
    }
    if (ellipsis && trimmed.length < value.length) {
        return `${trimmed} …`;
    }
    return trimmed;
};
