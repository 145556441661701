// extracted by mini-css-extract-plugin
export var IconContainer = "MainNavigation-module--IconContainer--YdnOS";
export var container = "MainNavigation-module--container--+t0IK";
export var content = "MainNavigation-module--content--mREy3";
export var cross = "MainNavigation-module--cross--uks8G";
export var desktopNav = "MainNavigation-module--desktopNav--uMuQJ";
export var hamburger = "MainNavigation-module--hamburger--HOBZc";
export var list = "MainNavigation-module--list--6RUhQ";
export var listItems = "MainNavigation-module--listItems--RP3nH";
export var listLinks = "MainNavigation-module--listLinks--+PJY+";
export var logo = "MainNavigation-module--logo--s7h20";
export var logoLink = "MainNavigation-module--logoLink--MjK9v";
export var mobileNav = "MainNavigation-module--mobileNav--qYQdw";
export var mobileNavOpen = "MainNavigation-module--mobileNavOpen--hsLdO";