import { allowSoftHyphens, stripTagFromHtml } from "../../helpers/string";
import { HeadlineTag } from "../../_typescript/type";
import * as React from "react";
import { BaseSyntheticEvent } from "react";

interface IHeadline {
  tagName: HeadlineTag;
  headline: string;
  rawHTML?: boolean;
  className?: string;
  containerClassName?: string;
  // eslint-disable-next-line no-unused-vars
  onClick?: (e: BaseSyntheticEvent) => void;
}

const Headline = ({
  tagName,
  headline,
  containerClassName,
  className,
  rawHTML = true,
  onClick,
}: IHeadline) => {
  if ("" === headline) {
    return null;
  }
  if (!tagName) {
    tagName = "h2";
  }

  headline = allowSoftHyphens(headline);
  // because we use CKEditor we often have a <p> around the actual headline
  headline = stripTagFromHtml("p", headline);

  const Element = tagName;

  if (rawHTML) {
    return (
      <div className={containerClassName} onClick={onClick}>
        <Element
          // @ts-ignore
          className={className}
          dangerouslySetInnerHTML={{
            __html: headline,
          }}
        />
      </div>
    );
  }

  return (
    <header className={containerClassName} onClick={onClick}>
      {
        // @ts-ignore
        <Element className={className}>{headline}</Element>
      }
    </header>
  );
};

export default Headline;
